import React from "react";

import { Header } from "../components/parts/Header";
import { Privacy } from "../components/Privacy";
import { Footer } from "../components/Footer";

import { Layout } from "../components/parts/Layout";

const TermsPage = () => {
  return (
    <Layout>
      <Header
        title={"プライバシーポリシー"}
        description={"プライバシーポリシー"}
      />
      <Privacy />
      <Footer />
    </Layout>
  )
}

export default TermsPage
