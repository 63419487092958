import React from "react";

import { WhiteWrapper } from "../components/parts/WhiteWrapper"
import { Container } from "../components/parts/Container";
import { TitleView } from "../components/parts/TitleView";

export const Privacy = () => {
  return(
    <WhiteWrapper>
      <Container>
        <TitleView>
          プライバシーポリシー
        </TitleView>
        <p>訪問者のプライバシーは訪問者及び当サイトにとって非常に重要なものです。</p>
        <p>このプライバシーポリシーの文書は、当サイトが個人情報の類を、どのように収集し、どのように使用しているのかを説明しています。</p>
        <h2>ログファイル</h2>
        <p>他の多くのWebサイトと同様に、当サイトでは、ログファイルを使用しています。</p>
        <p>ログファイルの内容は、IPアドレス、ブラウザの種類、インターネットサービスプロバイダ（ISP）、日付/タイムスタンプ、閲覧・出口ページ、クリック数等、当サイト周りでの訪問者の動向を統計情報として分析させていただくためのものであり、個人の情報を特定できるものではありません。</p>
        <h2>クッキーとWebビーコン</h2>
        <p>当サイトでは訪問者についての情報を収集する手段としてCookieを使用いたします。</p>
        <p>これは訪問者の閲覧ブラウザーの種類やその他の情報を収集し、訪問者のネット環境にあわせて当サイトの表示内容を変動させるためです。</p>
        <p>当サイトでは、第三者配信による広告サービスを利用し、ユーザー様が閲覧する他のサイト（Google AdSense広告のあるサイト、YouTube）の状況に応じて、興味のある広告を配信しています。</p>
        <p>そのため、当サイトや他サイトへのアクセスに関する情報 (氏名、住所、メール アドレス、電話番号は含まれません) を使用することがあります。</p>
        <p>当サイトや他サイトへのアクセスに関する情報 (氏名、住所、メールアドレス、電話番号は含まれません) を使用されないようにする方法については、<a href="https://support.google.com/analytics/answer/181881?hl=ja" target="_blank" rel="noopener noreferrer">こちら</a>をクリックし、オプトアウトの設定変更をお願いします。</p>
      </Container>
    </WhiteWrapper>
  )
}
